@import '../../../theme/styles/mixins';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    top: -50px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.modalContent {
  position: relative;
  background-color: var(--colors-surfaces-primary-0);
  margin: auto;
  padding: 0;
  width: auto;
  box-shadow: 0px 3px 20px #00000029;

  border-radius: 12px;
  outline: 0;
  padding: 20px;

  animation: fadeInUp 0.15s ease-out;
}

@include mobile {
  .bottomSheet,
  .fullScreen {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .bottomSheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    animation: slideInUp 0.3s ease-out;
  }

  .fullScreen {
    height: 100%;
    border-radius: 0;
    animation: fadeIn 0.15s ease-out;
  }
}

.modalHeader {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}
