@import '../../../../theme/styles/mixins.scss';

.container {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 30px 20px;

  display: none;
  flex-direction: column;

  background-color: var(--colors-surfaces-primary-0);

  @include mobile {
    &.isDrawerVisible {
      display: flex;
    }
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;

    > button {
      width: 36px;
      height: 36px;
      padding: 10px;
    }

    .userInfoContainer {
      display: flex;
      flex-direction: row;
      word-break: break-word;

      h2 {
        line-height: 37.2px;
      }

      .userMetaContainer {
        margin: 0 12px;
      }
    }
  }

  .contentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .authActionsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    > button {
      width: 100%;
      padding: 20px 24px;
      margin-bottom: 20px;
    }

    .companySignUp {
      background-color: var(--colors-surfaces-primary-0);

      &::after {
        background-color: var(--colors-surfaces-primary-0);
      }
    }
  }

  .navigationList {
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
    border-bottom: 1px solid var(--colors-text-body-2);

    .navItem {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 6px 0px;
      margin-top: 16px;

      > svg {
        width: 20px;
        height: 20px;
        margin-right: 16px;
        fill: var(--colors-button-text-primary-0);
      }

      &.disabled {
        opacity: 0.2;
      }
    }
  }

  .accountActionsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    border-bottom: 1px solid var(--colors-text-body-2);

    .actionItem {
      align-items: center;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  .footerContainer {
    display: flex;
    flex-direction: column;
    padding-top: 24px;

    .footerItem {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 6px 0px;

      > svg {
        width: 20px;
        height: 20px;
        margin-right: 16px;
        fill: var(--colors-button-text-primary-0);
      }
    }
  }

  .isOpen {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
}
