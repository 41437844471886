@import '../../../../theme/styles/mixins';

.actionButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    z-index: 1;
    height: 40px;

    span {
      font-weight: var(--font-heading-weight);
    }

    svg {
      fill: var(--colors-button-text-secondary-0);
    }

    @include mobile {
      padding: 10px 15px;
    }
  }
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-2px);
  }
  40% {
    transform: translateX(2px);
  }
  60% {
    transform: translateX(-2px);
  }
  80% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

.cartBtnSkeleton {
  margin-right: 15px;
  height: 40px !important;
  width: 118px !important;
  border-radius: 1000px;

  @include mobile {
    width: 63px !important;
  }
}

.signInButton {
  @include mobile {
    display: none;
  }
}
