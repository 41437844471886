@import './../../theme/styles/mixins.scss';

.container {
  position: sticky;
  bottom: 0;
  box-shadow: 0px -2px 10px rgb(0 0 0 / 10%);

  display: flex;
  flex-direction: column;

  background-color: var(--colors-surfaces-primary-0);
  padding: 15px 20px;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  > p {
    color: var(--colors-text-body-4);
    margin-bottom: 5px;
  }

  @include mobile {
    position: absolute;
    width: 100%;
  }
}

.footerActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include mobile {
    align-items: center;
    flex-direction: column;
  }

  > button {
    min-width: 240px;
    height: 45px;
    margin-left: 10px;

    @include mobile {
      width: 100%;
      margin-top: 10px;
    }

    span {
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: var(--colors-button-primary-content);
      margin-left: 6px;
      margin-right: 6px;
    }
  }
}

.quantityChange {
  p {
    font-size: 18px;
    font-weight: var(--font-heading-weight);
  }

  button {
    width: 45px;
    height: 45px;
  }
}
