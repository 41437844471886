@import '../../../theme/styles/mixins';

.container {
  margin-top: 15px;
  overflow-y: auto;
  padding: 0px 20px 40px 20px;
}

.emptyList {
  border: none;
  padding: 20px 0;

  img {
    height: 75px;
    width: 75px;

    @include mobile {
      height: 65px;
      width: 65px;
    }
  }

  h2 {
    font-size: 16px;
  }

  p {
    margin: 10px 0 0;
  }
}
