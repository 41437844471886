.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--colors-text-body-5);
    margin-right: 10px;
  }
}

.title {
  display: flex;
  flex-direction: row;
}

.subtitle {
  &.error {
    font-weight: var(--font-heading-weight);
    color: var(--colors-text-body-4);
  }
}
