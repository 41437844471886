@import '../../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: auto;

  width: 520px;
  height: 662px;
  max-height: calc(var(--vh100) - 20px);

  @include mobile {
    width: 100%;
    max-height: var(--vh100);
  }

  // Close Button
  > button {
    z-index: 2;
    top: 20px;
    right: 20px;
    width: 36px;
    height: 36px;
    position: absolute;
    padding: 5px;
  }
}

.topContainer {
  padding: 20px 20px 90px 20px;
}

.upsellListing {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.footerContainer {
  width: 100%;
  position: absolute;
  bottom: 0;
  box-shadow: 0px -2px 10px rgb(0 0 0 / 10%);

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--colors-surfaces-primary-0);
  padding: 15px 20px;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  > button {
    min-width: 240px;
    height: 45px;

    @include mobile {
      width: 100%;
      margin-top: 10px;
    }
  }

  @include mobile {
    position: absolute;
    width: 100%;
  }
}

.floatingHeader {
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background-color: var(--colors-surfaces-primary-0);

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);

  opacity: 0;
  transition:
    opacity 0.5s ease,
    transform 0s ease;

  &.show {
    opacity: 1;
  }

  h2 {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px 70px;
    text-align: center;
  }
}
