@import '../../../../theme/styles/mixins';

.localeSwitchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.isOrderPage {
    @include mobile {
      display: none;
    }
  }

  .localeSwitchButton {
    background-color: var(--colors-components-navBar-background);
    text-transform: capitalize;
    border-radius: 100px;

    svg {
      fill: var(--colors-components-navBar-text-0);
    }
  }
}

.localeSwitchLabel {
  font-family: var(--font-body-family);
  font-weight: var(--font-heading-weight);
  color: var(--colors-components-navBar-text-0);
}

.divider {
  height: 35px;
  width: 1px;
  background-color: var(--colors-components-navBar-text-0);
  margin: 0 15px;
}

.localSwitchDropdown {
  margin-top: 2px;
}
