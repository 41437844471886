.container {
  position: relative;

  button {
    margin: 0;
  }
}

.toggle {
  cursor: pointer;
  background-color: var(--colors-surfaces-secondary-0);

  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@keyframes fadeInUp {
  from {
    top: 95%;
    opacity: 0;
  }
  to {
    top: 100%;
    opacity: 1;
  }
}

.popup {
  position: absolute;
  top: 100%;
  margin-left: 0;
  z-index: 1;
  background-color: var(--colors-surfaces-primary-0);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 8px 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  animation: fadeInUp 0.5s ease;
}

.dropdownItem {
  cursor: pointer;
  font-family: var(--font-body-family);
  font-weight: var(--font-heading-weight);
  min-width: 110px;
  margin: 2px 0;
  padding: 2px 10px;
  width: 100%;

  &:hover {
    background-color: var(--colors-surfaces-primary-1);
  }
}

.topStartPlacement {
  top: 100%;
  left: 0;
}

.topEndPlacement {
  top: 100%;
  right: 0;
}

.selected {
  background-color: var(--colors-highlights-1);
}
