@import '../../../../theme/styles/mixins';

.container {
  padding: 10px 15px;
  border-radius: 50px;
  display: flex;
  justify-content: flex-start;
  border: none;
  align-items: center;
  cursor: pointer;
  height: 42px;

  &::after,
  &:hover::after,
  &:focus::after {
    background-color: var(
      --colors-components-navBar-button-background-0
    ) !important;
  }

  @include tablet {
    min-width: 180px;

    &::after,
    &:hover::after,
    &:focus::after {
      background-color: var(
        --colors-button-secondary-background-default
      ) !important;
    }
  }

  span {
    color: var(--colors-components-navBar-button-content-default-0);

    @include tablet {
      color: var(--colors-button-text-secondary-0);
    }
  }

  &.navBar {
    @include tablet {
      display: none;
    }

    @media screen and (min-width: 992px) and (max-width: 1150px) {
      &.whiteLabeled {
        .orderFormatData {
          .orderTypeIcon {
            padding: 0;

            svg {
              margin-right: 0px;
            }
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  @include smallMobile {
    width: 100%;
    max-width: calc(100vw - 30px);
  }
}

.orderFormatData {
  display: flex;
  align-items: center;

  > div {
    display: flex;
  }

  svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    fill: var(--colors-components-navBar-button-content-accent-0);

    @include tablet {
      fill: var(--colors-button-text-primary-0);
    }
  }
}
