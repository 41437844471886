@import '../../../../theme/styles/mixins';

.container {
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colors-components-navBar-button-background-0);
  padding: 5px;
  height: 42px;

  @include tablet {
    background-color: var(--colors-button-secondary-background-default);
    border: 1px solid
      var(
        --colors-button-secondary-outline,
        var(--colors-button-secondary-background-default)
      );
  }

  @include smallMobile {
    width: 100%;
    max-width: calc(100vw - 30px);
  }

  &.navBar {
    @include tablet {
      display: none;

      svg {
        fill: var(--colors-button-text-primary-0);
      }
    }
  }
}

.capitalize {
  text-transform: capitalize;
}
