.footer {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  padding: 0 20px;
  margin-bottom: 20px;

  button {
    flex: 1;
    font-size: 12px;
  }
}

.footer::after {
  content: '';
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}
